<template>
    <div>
        <div class="page-header justify-content-start">
            <lable class="page-title">Switch Account</lable>
            <!-- <ul class="breadcrumbs">
                <li>Raj Sinha > Pending Task (04)</li>
            </ul> -->
        </div>
        <div class="switch_accounts_list">
          <div class="switch_accounts">
            <div class="switch_account_details">
              <div>
                <figure class="dp">
                  <span><img src="@/assets/images/pooja.svg"></span>
                  <figcaption>Pooja H</figcaption>  
                </figure>
                <h6>Operations Head</h6>
              </div>
              <a href="#">Switch Account</a>
            </div>
            <ul>
              <li>
                <p>COMPLETED<span>102</span></p>
              </li>
              <li>
                <p>PENDING<span>102</span></p>
              </li>
              <li>
                <p>over due<span>102</span></p>
              </li>
            </ul>
          </div>
          <div class="switch_accounts">
            <div class="switch_account_details">
              <div>
                <figure class="dp">
                  <span><img src="@/assets/images/heera.svg"></span>
                  <figcaption>Heera Chand</figcaption>  
                </figure>
                <h6>CTO</h6>
              </div>
              <a href="#">Switch Account</a>
            </div>
            <ul>
              <li>
                <p>COMPLETED<span>900</span></p>
              </li>
              <li>
                <p>PENDING<span>23</span></p>
              </li>
              <li>
                <p>over due<span>07</span></p>
              </li>
            </ul>
          </div>
          <div class="switch_accounts">
            <div class="switch_account_details">
              <div>
                <figure class="dp">
                  <span><img src="@/assets/images/Chandani.svg"></span>
                  <figcaption>Chandani Sho</figcaption>  
                </figure>
                <h6>Marketing Head</h6>
              </div>
              <a href="#">Switch Account</a>
            </div>
            <ul>
              <li>
                <p>COMPLETED<span>300</span></p>
              </li>
              <li>
                <p>PENDING<span>76</span></p>
              </li>
              <li>
                <p>over due<span>08</span></p>
              </li>
            </ul>
          </div>
        </div>
    </div>
</template>

<script> 
  export default {
    components: {
         
    },
 
  }
</script>